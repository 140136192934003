import {h, Component} from 'preact';
import PropTypes from 'prop-types';
import getUTM from '%/utils/utm';
import handleInputChange from '%/utils/handleInputChange';
import validationFactory from '%/utils/validation';
import IMask from 'imask';
import {phoneMasks} from '%/utils/masks';
import classNames from 'classnames';
import FormMessageOverlay from "../FormMessageOverlay";
import CustomCheck from "../CustomCheck";
import SubmitButton from "../SubmitButton";
import InputCpf from "../InputCpf";

export default class ConversionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      email: null,
      phone: null,
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      dataPermissions: false,
      selectedVersion: '',
      selectedPeriod: '',
      selectedPlan: '',
      cpf: '',
    };

    this.getFormData = this.getFormData.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContactOptionsChange = this.handleContactOptionsChange.bind(this);
    this.watchStore()
  }

  watchStore() {
    window.store.Subscription.watch(({selectedVersion, selectedPeriod, selectedPlan}) => {
      selectedVersion && this.setState({selectedVersion: selectedVersion.slug});
      selectedPeriod && this.setState({selectedPeriod: selectedPeriod.value});
      selectedPlan && this.setState({selectedPlan: selectedPlan.value});
    })
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e)
    this.setState({contactOptions: this.contactOptionsValue()}, () => {
      this.validator.validateAll(this.form);
    });
  }

  contactOptionsValue() {
    return !!(this.state.phoning || this.state.mailing || this.state.whatsapping)
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }


  handleSubmit(e) {
    e.preventDefault();
    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }

    this.props.handleSubmit(e, this.getFormData());
  }

  componentDidMount() {
    const phoneMask = IMask(this.phoneInput, {
      mask: [...phoneMasks]
    });
    phoneMask.on("accept", () => this.setState({ phone: phoneMask.value }));
    this.validator = validationFactory(`#${this.props.formId}`);
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    const {
      selectedVersion,
      selectedPeriod,
      selectedPlan,
      name,
      email,
      phone,
      mailing,
      phoning,
      whatsapping
    } = this.state;

    let props = {
      version: selectedVersion,
      period: selectedPeriod,
      plan: selectedPlan,
      name,
      email,
      phone,
      mailing,
      phoning,
      whatsapping,
      brand: this.props.brand,
      channel: this.props.channel,
      category: this.props.category,
      product: this.props.product,
      version: document.getElementsByName('version')[0].value,
      period: document.getElementsByName('period')[0].value,
      plan: document.getElementsByName('plan')[0].value,
      unit: this.props.unit
    };

    if (this.props.showCpf) {
      props.cpf = this.state.cpf
    }

    if (this.props.shouldShowDataPermissions) {
      props.data_permissions = this.state.dataPermissions
    }

    if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value
    } else {
      props['unit'] = this.state.unit
    }

    props = {
      bait: this.props.bait,
      channel: this.props.channel,
      category: 'Subscription',
      utmz: getUTM(),
      ...props,
    };

    this.props.hiddenFields.map(field => props[field.name] = field.value)

    return {...props};
  }

  render() {
    const {className} = this.props

    return (
      <form
        className={classNames('conversion-form', className)}
        ref={form => (this.form = form)}
        id={this.props.formId}
        onSubmit={this.handleSubmit}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />
        <div className="form-conversion__body">
          <fieldset>
            <div className="form-group">
              <label for="name">Nome</label>
              <input
                onChange={this.handleInputChange}
                value={this.state.name}
                type="text"
                maxLength={80}
                className="form-control"
                name="name"
                required
                placeholder="Nome"
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback"/>
            </div>
            <div className="form-group">
              <label for="email">E-mail</label>
              <input
                onChange={this.handleInputChange}
                value={this.state.email}
                type="email"
                maxLength={80}
                required={this.props.contactsWithDynamicRequired ?
                  this.state.mailing
                  : true}
                className="form-control"
                name="email"
                placeholder="E-mail"
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback"/>
            </div>
            <div className="form-group">
              <label for="phone">Telefone</label>
              <input
                onChange={this.handleInputChange}
                value={this.state.phone}
                ref={phoneInput => (this.phoneInput = phoneInput)}
                className="form-control"
                name="phone"
                required={this.props.contactsWithDynamicRequired ?
                  this.state.phoning || this.state.whatsapping
                  : true}
                type="phone"
                data-bouncer-target="#invalid-phone"
                placeholder="Telefone/Whatsapp"
                data-should-validate={this.props.contactsWithDynamicRequired ? String(this.state.phoning || this.state.whatsapping) : 'true'}
              />
              <div id="invalid-phone" className="invalid-feedback"/>
            </div>
            {this.props.showCpf && <label for="cpf">CPF</label>}
            {
              this.props.showCpf &&
              <InputCpf
                handleChange={this.handleInputChange}
                value={this.state.cpf}
              />
            }

            {this.props.units &&
              this.props.units.length > 1 &&
              this.props.showUnits &&
              <div className="form-group">
                <label className="conversion-form__control-label">Escolha a unidade:</label>
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="unit"
                  options={this.props.units}
                  value={this.state.unit}
                  shouldSort={false}
                  placeholderValue="Selecione uma unidade"
                  searchEnabled={false}
                />
              </div>
            }
          </fieldset>
          <fieldset>
            <legend className="conversion-form__control-label">Quero receber contato por:</legend>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="mailing"
                value="true"
                onChangeCheckable={this.handleContactOptionsChange}
                isChecked={this.state.mailing}
                type="checkbox"
              >
                E-mail
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="whatsapping"
                value="true"
                isChecked={this.state.whatsapping}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
              >
                Whatsapp
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="phoning"
                value="true"
                isChecked={this.state.phoning}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
              >
                Telefone
              </CustomCheck>
            </div>
            <div>
              <input
                type="text"
                className="form-control d-none"
                name="contact-options"
                required
                placeholder="Opções de contato"
                data-bouncer-target="#invalid-contact-options"
                value={this.state.contactOptions ? 'true' : ''}
              />
              <div id="invalid-contact-options" className="invalid-feedback"/>
            </div>
          </fieldset>
          {this.props.shouldShowDataPermissions && (
            <fieldset>
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={this.state.dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                >
                  Aceito compartilhar meus dados com {this.props.channelName}
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <SubmitButton
            classes="btn button button--large button--primary w-100 mb-0"
            label="Solicitar Proposta"
            isSubmitting={this.props.isSubmittingForm}
            handleClick={this.handleSubmit}
          />
          <footer className="form-conversion__footer">
            Conheça nossa <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>.
          </footer>
        </div>
      </form>
    );
  }
}

// default props
ConversionForm.defaultProps = {
  handleSubmit: function () {
  },
  handleCloseOverlay: function () {
  },
  handleVariantChange: function () {
  },
  handleContactOptionsChange: function () {
  },
  isSubmittingForm: false,
  errorSendingForm: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  product: '',
  origin: '',
  channel: '',
  utmz: '',
  channelName: 'Portal',
  bait: '-5',
  showCpf: false,
  showLastName: false,
  hiddenFields: [],
  unit: '',
  units: [],
  showUnits: false,
};

// props config
ConversionForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  handleContactOptionsChange: PropTypes.func,
  variants: PropTypes.array.isRequired,
  formId: PropTypes.string.isRequired,
  isSubmittingForm: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  showOverlay: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  bait: PropTypes.string,
  channelName: PropTypes.string,
  channel: PropTypes.string,
  product: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  origin: PropTypes.string,
  showCpf: PropTypes.bool,
  hiddenFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  unit: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  showUnits: PropTypes.bool,
};
