import {Component} from 'preact'
import PropTypes from 'prop-types'
import {
  resetList,
  setList,
  setPerPage,
  setMethodApi,
  nextPage,
  incrementList, addFilter
} from "../../stores/SearchFilterSubscriptions";
import ItemCard from "./ItemCard";
import HeaderFilterSubscriptions from "./HeaderFilterSubscriptions";
import isNil from "lodash/isNil";
import {getSearchParams} from "../../../portal_assinatura/utils/utils";

class ListSubscriptionsApp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loadingMore: false,
      pageList: 1,
    }

    const {perPage, internalPage} = this.props;

    if (internalPage) {
      this.applyFilters();
    }

    setMethodApi('getSubscriptions')
    resetList()
    setPerPage(perPage)
    

    window.store.SearchFilterSubscriptions.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
        showList: state.showList,
        showButtonLoadingMore: state.page < state.total_pages,
      })
    })

    this.handleOnLoadMore = this.handleOnLoadMore.bind(this);
  }

  componentDidMount() {
    setList();
    window.matchHeight.match('.subscriptions-list__list')
  }

  handleOnLoadMore(e) {
    const {internalPage} = this.props;

    if (internalPage) {
      e.preventDefault();
      nextPage();
      incrementList();
    }
  }


  applyFilters() {
    const {allowedFilters} = this.props

    const params = getSearchParams()

    Object.entries(params).forEach(filter => {
      const [key, value] = filter
      if (value !== '' && !isNil(value) && allowedFilters.includes(key)) {
        addFilter({[key]: value})
      }
    })
  }

  render() {

    const {list, loading, loadingMore, showButtonLoadingMore} = this.state;
    const {internalPage, internalPageUrl} = this.props;

    const loadingAny = loading || loadingMore;
    const loadingFirst = loadingAny && list.length <= 0;

    return (<div>
      <header className="subscriptions-list__header">
        <HeaderFilterSubscriptions
          listPageURLRedirect={internalPageUrl}
          internalPage={internalPage}
        />
      </header>
      <div className="subscriptions-list__body">
        {list.length <= 0 && <div className={"mb-5 d-flex justify-content-center"}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
        </div>}
        <div className="subscriptions-list__list">
          {list.length > 0 && list.map((item, index) =>
            <ItemCard key={item.id}
                      title={item.title}
                      pitchs={item.sales_pitch}
                      thumb={item.avatar.url || item.model.item_image.url}
                      price={item.most_basic_period.price_float} period={"mês"}
                      link={`${internalPageUrl}/${item.slug}`}/>
          )}
        </div>
      </div>
      <footer className="subscriptions-list__footer text-center">
        {(showButtonLoadingMore || !internalPage) && <a href={internalPageUrl}
                                                        className={`btn button button--ghost-secondary subscriptions-list__footer-btn text-uppercase ${loadingAny ? 'subscriptions-list__footer-btn--disabled' : ''}`}
                                                        onClick={this.handleOnLoadMore}>
          Ver mais ofertas
          {loadingAny && <span className="icon"><div className="spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div></span>}
          {!loadingAny && <span
            className={`icon ${internalPage ? 'icon-arrow-d' : 'icon-arrow-pointing-to-right'}`}></span>}
        </a>}
      </footer>
    </div>);
  }
}

ListSubscriptionsApp.defaultProps = {
  perPage: 6,
  internalPage: false,
  internalPageUrl: '',
  allowedFilters: ['q', 'category', 'brand', 'price[gteq]', 'price[lteq]']
}
ListSubscriptionsApp.propTypes = {
  perPage: PropTypes.number,
  internalPage: PropTypes.bool,
  internalPageUrl: PropTypes.string,
  allowedFilters: PropTypes.array,
}

export default ListSubscriptionsApp