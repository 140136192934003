import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Faq extends Component {
  constructor(props) {
    super(props);

    this.handleClick.bind(this);
  }

  componentDidMount() {
    const { faq } = this;
    Array.from(faq.querySelectorAll('.faq__item-description')).forEach(
      (item) => {
        item.style.height = `${item.scrollHeight}px`;
      }
    );
  }

  handleClick(id) {
    const currentId = this.state.activeTopicID;
    const newId = id === currentId ? null : id;

    this.setState({
      activeTopicID: newId,
    });
  }

  render() {
    const { topics, twoColumns } = this.props;
    const { activeTopicID } = this.state;

    return (
      <ul
        className={classNames('faq__items', {
          'faq--col-2': twoColumns,
        })}
        ref={(faq) => (this.faq = faq)}
      >
        {topics.length >= 1 &&
          topics.map((topic) => (
            <li
              className={classNames('faq__item', {
                active: topic.id == activeTopicID,
              })}
              onClick={() => this.handleClick(topic.id)}
              key={topic.id}
            >
              <h3 className="faq__item-title">
                {topic.title}
                <span className="expand-icon"></span>
              </h3>
              <h4 className="faq__item-description">{topic.description}</h4>
            </li>
          ))}
      </ul>
    );
  }
}

Faq.defaultProps = {
  topics: [],
  twoColumns: false,
};

Faq.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  twoColumns: PropTypes.bool,
};

export default Faq;
