import {h} from 'preact';
import {Component} from 'react';
import ConversionForm from './ConversionForm';
import PropTypes from 'prop-types';

export default class ConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false
    };

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseOverlay(e) {
    this.setState({showOverlay: false});
  }

  handleSubmit(e, params) {
    this.setState({isSubmittingForm: true}, () => {

      return serviceConversion
        .convert({
          ...params,
        })
        .then(() => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: false
          });
        })
        .catch(error => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: true
          });
          console.error(error);
        })
        .then(() => {
          this.setState({
            showOverlay: true
          });
        });
    });
  }

  render() {
    return (
      <ConversionForm
        formId="conversion-from-subscription"
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        showOverlay={this.state.showOverlay}
        handleSubmit={this.handleSubmit}
        handleCloseOverlay={this.handleCloseOverlay}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        channel={"Portal"}
        showCpf={this.props.showCpf}
        showUnits={this.props.showUnits}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        channelName={this.props.channelName}
        product={this.props.product}
        category={this.props.category}
        brand={this.props.brand}
        unit={this.props.unit}
        units={this.props.units}
      />
    );
  }
}

ConversionFormApp.defaultProps = {
  linkPrivacyPolicy: '',
  channelName: '',
  showCpf: false,
  showUnits: false,
  units: [],
};

ConversionFormApp.propTypes = {
  linkPrivacyPolicy: PropTypes.string,
  channelName: PropTypes.string,
  showCpf: PropTypes.bool,
  channel: PropTypes.string,
  product: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  showUnits: PropTypes.bool,
};
